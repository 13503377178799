const capacityTypeMap = {
    TRUNK: "Trunk",
    SHUTTLE: "Shuttle",
    NS_MAIN: "N/S Main",
    FEEDER: "Feeder"
};

const reverseCapacityTypeMap = Object.fromEntries(
    Object.entries(capacityTypeMap).map(([key, value]) => [value, key])
);

export const mapCapacityType = (capacityType) => {
    return capacityTypeMap[capacityType] || capacityType;
};

export const mapLabelToCapacityType = (label) => {
    return reverseCapacityTypeMap[label] || label;
};
