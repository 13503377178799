import { OctwRule } from "../../../../http/clients/OctwClient";
import { Maybe } from "../../../common/types";
import {EditRuleFormShape} from "../EditDefaultValuesDrawer/EditDefaultValuesDrawer";

export type DefaultValueDto = {
    moves: Maybe<number>;
    yardCapacity: Maybe<number>;
    reeferPlugs: Maybe<number>;
    terminalCode: Maybe<string>;
    moveCountStretchPercentage: Maybe<number>;
    moveCountStretchNumber: Maybe<number>;
    moveCountCriticalPercentage: Maybe<number>;
    moveCountCriticalNumber: Maybe<number>;
};

export const createDefaultValueDto = (
  formData: EditRuleFormShape,
): DefaultValueDto => {
  return {
    moves: formData.moves,
    yardCapacity: formData.yardCapacity,
    reeferPlugs: formData.reeferPlugs,
    terminalCode: formData.terminalCode,
    moveCountStretchPercentage: formData.moveCountStretchPercentage,
    moveCountStretchNumber: formData.moveCountStretchNumber,
    moveCountCriticalPercentage: formData.moveCountCriticalPercentage,
    moveCountCriticalNumber: formData.moveCountCriticalNumber
  };
};
